import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "@annotationhub/react-golden-layout/dist/css/goldenlayout-base.css";
import "@annotationhub/react-golden-layout/dist/css/themes/goldenlayout-dark-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import DockLayout from "rc-dock";
import "rc-dock/dist/rc-dock.css";
import "./rc-dock-custom.css";
import { Badge, Skeleton, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ApplicationApi from "../../api/ApplicationApi";
import { Modal } from "antd";
import { Card } from "antd";
import { Col, Row } from "antd";
import { Typography } from "antd";
const { Title } = Typography;
const { Meta } = Card;

import {
  UserPanelU,
  TemplateManagerU,
  ServiceSettingU,
  AdminPanelU,
  createIdAdminPanel,
  createIdConsole,
  createIdEditorPage,
  createIdLoading,
  createIdNodeRed,
  createIdUserA,
  defaultLayout,
  GlobalSidebar,
  groups,
  LoadingItem,
  NodeRed,
  Tab,
  replaceItemDockLayout,
  savedSettings,
  savedWidth,
  UserA,
  createIdUserPanel,
  createIdTemplateManager,
  createIdServiceSetting,
  createIdUserSettings,
  UserSettings,
  CustomTitleTabElement,
  createIdWorkspaceSettings,
  WorkspaceSetting,
  DemoTemplate,
  createIdDemoTemplate,
  UserQuest,
  createIdUserQuest,
  createIdTemplateManagerV2,
  TemplateManagerUV2,
  createIdTab,
} from "./DoclLayoutSettings";
import { ResizableBox } from "react-resizable";
import { UiEditor } from "./UiEditor";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import SideNavigation from "../AppViewer/SideNavigation";
import { getCurrentUser } from "selectors/usersSelectors";
import { useHistory, useLocation } from "react-router-dom";
import {
  QUERY_KEY_IS_ADDED_PAYMENT_CARD,
  QUERY_KEY_TEMPLATE_ID,
} from "constants/routes";
import { AppToaster } from "components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { sendUserLogs } from "api/userLogs";
import ModalWrapper from "./ModalWrapper";
import ToursWrapper from "./Tours/ToursWrapper";
import { ReactComponent as TemplateIcon } from "../../assets/images/work_space/templateIcon.svg";
import { ReactComponent as WorkspaceIcon } from "../../assets/images/work_space/SideIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/control/info-circle-icon.svg";
import { ReactComponent as GameIcon } from "../../assets/icons/control/game-logo.svg";

const Containers = styled.div`
  display: flex;
  height: calc(100vh - ${props => props.theme.headerHeight});
`;

const GlobalWrapper = styled.div`
  display: flex;
  height: calc(100vh - 34px);
  .wrapper_left_sidebar {
    display: flex;
    height: 100%;
    position: relative;
    left: 48px;
    top: 34px;

    .global_sidebar {
      width: 100%;
      height: 100%;
    }
  }
  .global_icon {
    position: fixed;
    left: 0;
    z-index: 24;
    background: #343535;
    width: 40px;
    height: 100%;
    svg {
      display: block;
      margin: auto;
      margin-top: 10px;
    }
    svg:hover {
      cursor: pointer;
    }
  }
  .react-resizable {
    position: relative;
    height: 100% !important;
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    position: absolute;
    height: 100%;
    cursor: e-resize;
    z-index: 11;
    width: 6px;
    background: #f3f3f3;
    border-right: 1px solid #d9d9d9;
    opacity: 0;
  }
`;

// const EditorContainer = styled.div`
//   position: relative;
// `;

const widthLeftGlobalIcon = 48;
const WidthLeftMenu = 230;
const PaddingLayout = WidthLeftMenu + widthLeftGlobalIcon;

export const Timer = (props: any) => {
  const createdAt = new Date(props.createdAt);
  const targetTime = new Date(createdAt.getTime() + 3 * 60 * 60 * 1000); // Дата, коли таймер має завершитися
  const [timeRemaining, setTimeRemaining] = useState(targetTime - new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const remainingTime = targetTime - currentTime;

      if (remainingTime > 0) {
        setTimeRemaining(remainingTime);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (ms: any) => {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const TimerStyled = styled.p`
    font-size: 18px;

    > span {
      color: rgb(59, 130, 255);
      font-weight: 700;
    }
  `;

  return (
    <TimerStyled>
      Hurry - Offer expires in{" "}
      <span style={{ color: "#3b82ff" }}>{formatTime(timeRemaining)}</span>
    </TimerStyled>
  );
};

export const getURLForCustomTitleTab = (
  appName: string,
  additionalProps?: any,
) => {
  let url = "";

  if (appName === "UserSettings") url = `https://us.${location.host}/`;
  if (appName === "ServiceSetting")
    // url = `https://service-setting.${location.host}/Overview`;

    url = `https://service-setting.${location.host}/Overview%20New`;
  if (appName === "WorkspaceSetting")
    url = `https://service-setting.${location.host}/Server`;
  if (appName === "SystemServices") url = `https://sys-adm.${location.host}/`;
  if (appName === "DeploymenManager")
    url = `https://deployment-manager.${location.host}/`;
  if (appName === "UserSettingsLoad") url = `https://us.${location.host}/Load`;
  if (appName === "TemplateManager")
    url = `https://template-manager.${location.host}/`;
  if (appName === "DemoTemplate")
    url = `https://aibotui-8988-658ad621387fb711000001e1.ubos.tech/Login?email=${additionalProps.useEmail}`;
  if (appName === "UserQuest") url = `https://user-quest.${location.host}/`;

  return url;
};

const MainContainer = () => {
  const getLocation = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(getLocation.search);
  const templateId = queryParams.get(QUERY_KEY_TEMPLATE_ID);
  const isAddedPaymentCard = queryParams.get(QUERY_KEY_IS_ADDED_PAYMENT_CARD);

  const [templatedemo, setTemplatedemo] = useState(false);
  const [contentOld, setContentOld] = useState<any>();
  const [isLoadingLayoutList, setIsLoadingLayoutList] = useState(true);
  const [openLeftMenu, setOpenLeftMenu] = useState<any>(true);
  const [paddingLayout, setPaddingLayout] = useState(PaddingLayout);
  const [widthLeftMenu, setWidthLeftMenu] = useState(WidthLeftMenu);
  const [userPermissions, setUserPermissions] = useState<any>(true);
  const [userTypePermissions, setUserTypePermissions] = useState<any>([]);

  const [templateID, setTemplateID] = useState(false);

  const dispatch = useDispatch();
  const layoutRef = useRef<any>(null);

  const leftSidebarRef = useRef<any>(null);

  const dockLayoutItem = useSelector((state: AppState) => {
    return state.layout.dockLayoutItem;
  });

  const dockLayoutRemoveItem = useSelector(
    (state: AppState) => state.layout.dockLayoutRemoveItem,
  );

  const currentApplicationID = useSelector((state: AppState) => {
    return state.ui.applications?.currentApplication?.id;
  });
  const currentOrg = useSelector((state: AppState) => {
    return state.ui.orgs.currentOrg;
  });
  const organizations = useSelector((state: AppState) => {
    return state.ui.applications.userOrgs;
  });

  const curApplicationID = useSelector((state: AppState) => {
    return state.ui.applications?.curApplication;
  });

  const userModals = useSelector((state: AppState) => {
    return state.ui.users.modals;
  });

  useEffect(() => {
    // In the parent window
    const receiveMessage = event => {
      // Check the origin of the message to ensure it's from a different origin
      const currentOrigin = window.location.origin;
      if (event.origin !== currentOrigin) {
        try {
          // Access the data sent from the iframe
          if (event.data.type) {
            dispatch(event.data);
          } else {
            eval(event.data);
          }
        } catch (e) {
          console.log(e);
        }

        // You can perform actions based on the received message
      }
    };

    window.addEventListener("message", receiveMessage, false);
    if (templateId == "6683ed1f52da610011914678") {
      setTemplatedemo(true);
      setTemplateID(true);
    }

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("message", receiveMessage);
      setTemplateID(false);
    };
  }, []);

  useEffect(() => {
    const result = organizations.filter(
      el => el.organization.id == currentOrg.id,
    );
    if (result.length > 0) {
      const curPermision = result[0].organization.userPermissions;

      const check = curPermision?.some(
        el => el == "manage:workspace" || el == "manage:organizations",
      );
      const permision_type = curPermision;
      setUserTypePermissions(permision_type);
      setUserPermissions(check);
    }
  }, [currentOrg]);

  useEffect(() => {
    if (!curApplicationID) {
      dispatch({
        type: ReduxActionTypes.CUR_APP,
        payload: currentApplicationID,
      });
    }
    if (curApplicationID == currentApplicationID) {
      (async () => {
        const settings = await ApplicationApi.getLayoutApplication(
          curApplicationID || currentApplicationID || "",
        );
        if (settings.data.layout_setting) {
          setContentOld(replaceItemDockLayout(settings.data.layout_setting));
        } else {
          setContentOld("default");
        }

        setIsLoadingLayoutList(false);
      })();
      const interval = setInterval(() => {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentApplicationID, curApplicationID]);

  useEffect(() => {
    if (layoutRef?.current) {
      if (dockLayoutItem.component === "UserSettings") {
        const nodeRedElement = layoutRef.current.find(
          createIdUserSettings(dockLayoutItem),
        );
        if (!nodeRedElement) {
          const newItem = {
            id: createIdUserSettings(dockLayoutItem),
            content: <UserSettings options={dockLayoutItem.props} />,
            title: (
              <CustomTitleTabElement
                title={dockLayoutItem.title}
                url={
                  dockLayoutItem.props.navigateTo === "Load"
                    ? getURLForCustomTitleTab("UserSettingsLoad")
                    : getURLForCustomTitleTab("UserSettings")
                }
              />
            ), // `${dockLayoutItem.title}`,
            group: "allowWindow",
            closable: true,
          };
          const current = layoutRef?.current?.saveLayout();
          layoutRef.current.dockMove(
            newItem,
            current.dockbox.children[0].tabs.length != 0
              ? current.dockbox.children[0].tabs[0].id
              : current.dockbox.children[0].id,
            current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
          );
          savedSettings(layoutRef, currentApplicationID);
        } else {
          layoutRef.current.updateTab(
            createIdUserSettings(dockLayoutItem),
            nodeRedElement,
            true,
          );
        }
      } else {
        if (dockLayoutItem.component === "serviceSetting") {
          const nodeRedElement = layoutRef.current.find(
            createIdServiceSetting(dockLayoutItem),
          );

          if (!nodeRedElement) {
            const newItem = {
              id: createIdServiceSetting(dockLayoutItem),
              content: <ServiceSettingU props={dockLayoutItem.props} />,
              title: (
                <CustomTitleTabElement
                  title={dockLayoutItem.title}
                  url={getURLForCustomTitleTab("ServiceSetting")}
                  icon={InfoIcon}
                />
              ),
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            layoutRef.current.updateTab(
              createIdServiceSetting(dockLayoutItem),
              nodeRedElement,
              true,
            );
          }
        } else if (dockLayoutItem.component === "nodeRed") {
          const nodeRedElement = layoutRef.current.find(
            createIdNodeRed(dockLayoutItem),
          );
          if (!nodeRedElement) {
            const newItem = {
              id: createIdNodeRed(dockLayoutItem),
              content: (
                <NodeRed
                  url={dockLayoutItem.props}
                  queryParams={dockLayoutItem?.queryParams}
                />
              ),
              // title: dockLayoutItem.title,
              title: (
                <CustomTitleTabElement
                  title={dockLayoutItem.title}
                  url={dockLayoutItem.props}
                  icon={dockLayoutItem?.icon}
                />
              ),

              group: "allowWindow",
              closable: true,
            };
            // const UIEditor = layoutRef.current.find(
            //   createIdEditdockLayoutItemorPage(),
            // );
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            layoutRef.current.updateTab(
              createIdNodeRed(dockLayoutItem),
              nodeRedElement,
              true,
            );
          }
        } else if (dockLayoutItem.component === "Tab") {
          const nodeRedElement = layoutRef.current.find(
            createIdTab(dockLayoutItem),
          );
          if (!nodeRedElement) {
            const newItem = {
              id: createIdTab(dockLayoutItem),
              content: <Tab {...dockLayoutItem.props} />,
              // title: dockLayoutItem.title,
              title: (
                <CustomTitleTabElement
                  title={dockLayoutItem.title}
                  url={dockLayoutItem.props}
                  icon={dockLayoutItem?.icon}
                />
              ),

              group: "allowWindow",
              closable: true,
            };
            // const UIEditor = layoutRef.current.find(
            //   createIdEditdockLayoutItemorPage(),
            // );
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            layoutRef.current.updateTab(
              createIdTab(dockLayoutItem),
              nodeRedElement,
              true,
            );
          }
        } else if (dockLayoutItem.component === "TemplateManagerV2") {
          const layoutId = createIdTemplateManagerV2(dockLayoutItem);
          const nodeRedElement = layoutRef.current.find(layoutId);

          if (!nodeRedElement) {
            const newItem = {
              id: layoutId,
              content: <TemplateManagerUV2 options={dockLayoutItem.props} />,
              title: (
                <CustomTitleTabElement
                  title={dockLayoutItem.props?.title}
                  url={dockLayoutItem.props?.url}
                  icon={TemplateIcon}
                />
              ),

              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            layoutRef.current.updateTab(layoutId, nodeRedElement, true);
          }
        } else if (dockLayoutItem.component === "Loading") {
          if (!layoutRef.current.find(createIdLoading(dockLayoutItem))) {
            const newItem = {
              id: createIdLoading(dockLayoutItem),
              content: (
                <LoadingItem
                  url={dockLayoutItem.props}
                  type={"Loading"}
                  id={createIdLoading(dockLayoutItem)}
                  title={dockLayoutItem.title}
                />
              ),
              title: dockLayoutItem.title,
              // title: (
              //   <CustomTitleTabElement
              //     title={dockLayoutItem.title}
              //     url={dockLayoutItem.props}
              //   />
              // ),
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef?.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
          } else {
            const element = layoutRef.current.find(createIdConsole());
            if (element) {
              layoutRef.current.updateTab(createIdConsole(), element, true);
            }
          }
        } else if (dockLayoutItem.component === "ContainerEditor") {
          const UIEditor = layoutRef.current.find(createIdEditorPage());
          if (!UIEditor) {
            const newItem = {
              id: createIdEditorPage(),
              title: "ContainerEditor",
              content: UiEditor,
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            if (current) {
              layoutRef.current.dockMove(
                newItem,
                current.dockbox.children[0].tabs.length != 0
                  ? current.dockbox.children[0].tabs[0].id
                  : current.dockbox.children[0].id,
                current.dockbox.children[0].tabs.length != 0
                  ? "after-tab"
                  : "left",
              );
            }
            savedSettings(layoutRef, currentApplicationID);
          } else {
            layoutRef.current.updateTab(createIdEditorPage(), UIEditor, true);
          }
        } else if (dockLayoutItem.component === "TemplateManager") {
          if (
            !layoutRef.current.find(createIdTemplateManager(dockLayoutItem))
          ) {
            const newItem = {
              id: createIdTemplateManager(dockLayoutItem),
              content: <TemplateManagerU options={dockLayoutItem.props} />,
              // title: "Template Manager",
              title: (
                <CustomTitleTabElement
                  title={
                    dockLayoutItem.title
                      ? dockLayoutItem.title
                      : "Asset Marketplace"
                  }
                  url={getURLForCustomTitleTab("TemplateManager")}
                  icon={TemplateIcon}
                />
              ),
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            const element = layoutRef.current.find(createIdTemplateManager());
            if (element) {
              layoutRef.current.updateTab(
                createIdTemplateManager(),
                element,
                true,
              );
            }
          }
        } else if (dockLayoutItem.component === "DemoTemplate") {
          if (!layoutRef.current.find(createIdDemoTemplate())) {
            const newItem = {
              id: createIdDemoTemplate(),
              content: <DemoTemplate />,
              title: (
                <CustomTitleTabElement
                  title={"AI Chat"}
                  url={
                    "https://aibotui-8988-658ad621387fb711000001e1.ubos.tech"
                  }
                />
              ),
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            const element = layoutRef.current.find(createIdDemoTemplate());
            if (element) {
              layoutRef.current.updateTab(
                createIdDemoTemplate(),
                element,
                true,
              );
            }
          }
        } else if (dockLayoutItem.component === "WorkspaceSetting") {
          if (!layoutRef.current.find(createIdWorkspaceSettings())) {
            const newItem = {
              id: createIdWorkspaceSettings(),
              content: <WorkspaceSetting />,
              title: (
                <CustomTitleTabElement
                  title={"Workspace Setting"}
                  url={getURLForCustomTitleTab("WorkspaceSetting")}
                  icon={WorkspaceIcon}
                />
              ),
              group: "allowWindow",
              closable: true,
            };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            const element = layoutRef.current.find(createIdWorkspaceSettings());
            if (element) {
              layoutRef.current.updateTab(
                createIdWorkspaceSettings(),
                element,
                true,
              );
            }
          }
        } else if (dockLayoutItem.component === "UserQuest") {
          if (!layoutRef.current.find(createIdUserQuest(dockLayoutItem))) {
            const newItem = {
              id: createIdUserQuest(dockLayoutItem),
              content: <UserQuest options={dockLayoutItem.props} />,
              title: (
                <CustomTitleTabElement
                  title={dockLayoutItem.title ? dockLayoutItem.title : "Quests"}
                  url={getURLForCustomTitleTab("UserQuest")}
                  icon={GameIcon}
                />
              ),
              group: "allowWindow",
              closable: true,
            };
            // const newItem = {
            //   id: createIdUserQuest(dockLayoutItem),
            //   content: <UserQuest options={dockLayoutItem.props}/>,
            //   title: "User quest",
            //   group: "allowWindow",
            //   closable: true,
            // };
            const current = layoutRef?.current?.saveLayout();
            layoutRef.current.dockMove(
              newItem,
              current.dockbox.children[0].tabs.length != 0
                ? current.dockbox.children[0].tabs[0].id
                : current.dockbox.children[0].id,
              current.dockbox.children[0].tabs.length != 0
                ? "after-tab"
                : "left",
            );
            savedSettings(layoutRef, currentApplicationID);
          } else {
            const element = layoutRef.current.find(
              createIdUserQuest(dockLayoutItem),
            );
            if (element) {
              layoutRef.current.updateTab(
                createIdUserQuest(dockLayoutItem),
                element,
                true,
              );
            }
          }
        } else {
          if (dockLayoutItem.component === "UserA") {
            if (!layoutRef.current.find(createIdUserA())) {
              const newItem = {
                id: createIdUserA(),
                content: <UserA />,
                title: "analytic",
                group: "allowWindow",
                closable: true,
              };
              const current = layoutRef?.current?.saveLayout();
              layoutRef.current.dockMove(
                newItem,
                current.dockbox.children[0].tabs.length != 0
                  ? current.dockbox.children[0].tabs[0].id
                  : current.dockbox.children[0].id,
                current.dockbox.children[0].tabs.length != 0
                  ? "after-tab"
                  : "left",
              );
              savedSettings(layoutRef, currentApplicationID);
            } else {
              const element = layoutRef.current.find(createIdUserA());
              if (element) {
                layoutRef.current.updateTab(createIdUserA(), element, true);
              }
            }
          }
        }
      }
    }
  }, [dockLayoutItem]);

  // useEffect(() => {
  //   if (layoutRef?.current) {
  //     const item = JSON.parse(dockLayoutUpdateItem.id);
  //     if (item.type == "Loading") {
  //       const healthCheck = setInterval(async () => {
  //         const response = await ApplicationApi.healthCheckerServices(
  //           curApplicationID || currentApplicationID || "",
  //           item,
  //         );
  //         if (response.data.status) {
  //           const newid = JSON.stringify({
  //             id: item.id,
  //             type: "nodeRed",
  //             props: item.props,
  //           });
  //           layoutRef.current.updateTab(
  //             dockLayoutUpdateItem.id,
  //             {
  //               id: newid,
  //               content: <NodeRed url={item.props} />,
  //               title: dockLayoutUpdateItem.title,
  //               group: "allowWindow",
  //               closable: true,
  //             },
  //             true,
  //           );
  //           clearInterval(healthCheck);
  //         }
  //       }, 5000);
  //     } else {
  //       const newid = dockLayoutUpdateItem.content.props.id;
  //       layoutRef.current.updateTab(
  //         dockLayoutUpdateItem.id,
  //         {
  //           id: newid,
  //           content: (
  //             <LoadingItem
  //               id={newid}
  //               title={item.id}
  //               type={"Loading"}
  //               props={item.props}
  //             />
  //           ),
  //           title: item.id,
  //           group: "allowWindow",
  //           closable: true,
  //         },
  //         true,
  //       );
  //     }
  //   }
  // }, [dockLayoutUpdateItem]);

  useEffect(() => {
    if (layoutRef?.current) {
      const element = layoutRef.current.find(
        JSON.stringify({
          id: dockLayoutRemoveItem.title,
          type: dockLayoutRemoveItem.component,
          props: dockLayoutRemoveItem.props,
          icon: dockLayoutRemoveItem?.icon,
        }),
      );

      if (element) {
        layoutRef.current.dockMove(element, null, "remove");
        savedSettings(layoutRef, currentApplicationID);
      } else {
        const rmItem = JSON.stringify({
          id: dockLayoutRemoveItem.id,
          type: dockLayoutRemoveItem.component,
          props: dockLayoutRemoveItem.props,
        });

        const el = layoutRef.current.find(rmItem);

        if (el) {
          layoutRef.current.dockMove(el, null, "remove");
          savedSettings(layoutRef, currentApplicationID);
        }
      }
    }
  }, [dockLayoutRemoveItem]);
  if (layoutRef?.current) {
    const element = layoutRef.current.find("delete");
    if (element) {
      layoutRef.current.dockMove(element, null, "remove");
    }
  }

  const handleAnalitic = () => {
    if (!layoutRef.current.find(createIdUserA())) {
      const newItem = {
        id: createIdUserA(),
        content: <UserA />,
        title: "analytic",
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdUserA());
      if (element) {
        layoutRef.current.updateTab(createIdUserA(), element, true);
      }
    }
  };
  const handleUserPanel = () => {
    if (!layoutRef.current.find(createIdUserPanel())) {
      const newItem = {
        id: createIdUserPanel(),
        content: <UserPanelU />,
        // title: "Admin Panel",
        title: (
          <CustomTitleTabElement
            title={"Admin Panel"}
            url={getURLForCustomTitleTab("DeploymenManager")}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdUserPanel());
      if (element) {
        layoutRef.current.updateTab(createIdUserPanel(), element, true);
      }
    }
  };

  const handleTemplateManager = (options?: any) => {
    sendUserLogs({
      ...userDataForEvents,
      type: "openTemplateManager",
    });

    if (!layoutRef.current?.find(createIdTemplateManager())) {
      const newItem = {
        id: createIdTemplateManager(),
        content: <TemplateManagerU options={options} />,
        // title: "Template Manager",
        title: (
          <CustomTitleTabElement
            title={"Asset Marketplace"}
            url={getURLForCustomTitleTab("TemplateManager")}
            icon={TemplateIcon}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdTemplateManager());
      if (element) {
        layoutRef.current.updateTab(createIdTemplateManager(), element, true);
      }
    }
  };

  // useEffect(() => {
  //   if (layoutRef.current && templateId) {
  //     handleTemplateManager();
  //   }
  // }, [layoutRef.current]);

  useEffect(() => {
    // if URL templateId
    if (curApplicationID && templateId && contentOld) {
      if (!isLoadingLayoutList && layoutRef.current) {
        console.log(currentOrg, "currentOrgcurrentOrgcurrentOrg");
        if (currentOrg.server) {
          const newItem2 = {
            component: "Tab",
            type: "Tab",
            title: "Clone template",
            icon:
              "https://ubos.tech/wp-content/uploads/2023/09/ubos-logo-sm.png",
            props: {
              baseUrl: `https://toolmanager.${location.host}/`,
              navigateTo: "Service%20creation",
              queryParams: {
                templateId: templateId,
                org: currentOrg.id,
                orgId: currentOrg.id,
                app: curApplicationID,
                folderId: undefined,
                userId: user?.id,
                userEmail: user?.email,
              },
            },
          };

          dispatch({
            type: ReduxActionTypes.SET_LAYOUT_ITEM,
            payload: newItem2,
          });
        } else {
          const newItem2 = {
            component: "Tab",
            type: "Tab",
            title: "Hardware",
            icon:
              "https://ubos.tech/wp-content/uploads/2023/09/ubos-logo-sm.png",
            props: {
              baseUrl: `https://provider.${location.host}/`,
              navigateTo: "choose%20provider",
              queryParams: {
                templateId: templateId,
                org: currentOrg.id,
                orgId: currentOrg.id,
                app: curApplicationID,
                folderId: undefined,
                userId: user?.id,
                userEmail: user?.email,
              },
            },
          };
          dispatch({
            type: ReduxActionTypes.SET_LAYOUT_ITEM,
            payload: newItem2,
          });
        }
      }

      const currentURL = window.location.href;
      const urlParts = currentURL.split("?");

      if (urlParts.length === 2) {
        const params = new URLSearchParams(urlParts[1]);
        if (params.has("templateId")) {
          params.delete("templateId");
          const newQueryString = params.toString();

          const newURL =
            urlParts[0] + (newQueryString ? "?" + newQueryString : "");
          console.log(newURL, "newURLnewURLnewURL");
          window.history.replaceState({}, document.title, newURL);
        }
      }
    }
  }, [curApplicationID, contentOld, isLoadingLayoutList, layoutRef]);

  const handleServiceSetting = () => {
    if (!layoutRef.current.find(createIdUserSettings())) {
      const newItem = {
        id: createIdServiceSetting(),
        content: <ServiceSettingU />,
        // title: "ServiceSetting",
        title: (
          <CustomTitleTabElement
            title={"ServiceSetting"}
            url={getURLForCustomTitleTab("ServiceSetting")}
            icon={InfoIcon}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdUserSettings());
      if (element) {
        layoutRef.current.updateTab(createIdUserSettings(), element, true);
      }
    }
  };

  const handleWorkspaceSetting = () => {
    sendUserLogs({
      ...userDataForEvents,
      type: "openWorkspaceSetting",
    });

    if (!layoutRef.current.find(createIdWorkspaceSettings())) {
      const newItem = {
        id: createIdWorkspaceSettings(),
        content: <WorkspaceSetting />,
        // title: "Workspace Setting",
        title: (
          <CustomTitleTabElement
            title={"Workspace Setting"}
            url={getURLForCustomTitleTab("WorkspaceSetting")}
            icon={WorkspaceIcon}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdWorkspaceSettings());
      if (element) {
        layoutRef.current.updateTab(createIdWorkspaceSettings(), element, true);
      }
    }
  };

  const handleOpenDemoTemplate = (options?: any) => {
    const newItem = {
      id: createIdDemoTemplate(),
      component: "DemoTemplate",
      title: (
        <CustomTitleTabElement
          title={"AI Chat"}
          url={"https://aibotui-8988-658ad621387fb711000001e1.ubos.tech"}
        />
      ),
    };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
    // sendUserLogs({
    //   ...userDataForEvents,
    //   type: "openWorkspaceSetting",
    // });
  };

  const handleAdminPanel = () => {
    if (!layoutRef.current.find(createIdAdminPanel())) {
      const newItem = {
        id: createIdAdminPanel(),
        content: <AdminPanelU />,
        // title: "System Services",
        title: (
          <CustomTitleTabElement
            title={"System Services"}
            url={getURLForCustomTitleTab("SystemServices")}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdAdminPanel());
      if (element) {
        layoutRef.current.updateTab(createIdAdminPanel(), element, true);
      }
    }
  };
  const handleUserSettings = (options?: any) => {
    sendUserLogs({
      ...userDataForEvents,
      type: "openUserSetting",
    });

    if (!layoutRef.current.find(createIdUserSettings(options))) {
      const newItem = {
        id: createIdUserSettings({ title: "User Settings" }),
        content: <UserSettings options={options} />,
        // title: "User Settings",
        title: (
          <CustomTitleTabElement
            title={"User Settings"}
            url={getURLForCustomTitleTab("UserSettings")}
          />
        ),
        group: "allowWindow",
        closable: true,
      };
      const current = layoutRef?.current?.saveLayout();
      layoutRef.current.dockMove(
        newItem,
        current.dockbox.children[0].tabs.length != 0
          ? current.dockbox.children[0].tabs[0].id
          : current.dockbox.children[0].id,
        current.dockbox.children[0].tabs.length != 0 ? "after-tab" : "left",
      );
      savedSettings(layoutRef, currentApplicationID);
    } else {
      const element = layoutRef.current.find(createIdUserSettings());

      if (element) {
        layoutRef.current.updateTab(createIdUserSettings(), element, true);
      }
    }
  };

  const handleMigrateWorkspace = () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    const newItem = {
      id: JSON.stringify({ id: "User Settings", type: "UserSettings" }),
      component: "UserSettings",
      title: "User Settings",
      props: { navigateTo: "Load" },
    };
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const handleLeftMenu = () => {
    if (openLeftMenu) {
      setPaddingLayout(widthLeftGlobalIcon);
    } else {
      setPaddingLayout(widthLeftMenu + widthLeftGlobalIcon);
    }
    setOpenLeftMenu(!openLeftMenu);
  };
  const onResize = (event: any) => {
    setPaddingLayout(leftSidebarRef.current.offsetWidth + widthLeftGlobalIcon);
    setWidthLeftMenu(leftSidebarRef.current.offsetWidth);
  };

  const user = useSelector((state: any) => getCurrentUser(state));

  const userDataForEvents = {
    userId: user?.id,
    userEmail: user?.email,
  };

  useEffect(() => {
    try {
      const scriptGoogleURL = document.createElement("script");
      const scriptTagGoogletagmanager = document.createElement("script");
      const comment = document.createComment(" Google tag (gtag.js) ");

      scriptGoogleURL.src =
        "https://www.googletagmanager.com/gtag/js?id=G-6LJ7TT6N3Z";
      scriptGoogleURL.async = true;

      scriptTagGoogletagmanager.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-6LJ7TT6N3Z');
      `;
      document.head.appendChild(comment);
      document.head.appendChild(scriptGoogleURL);
      document.head.appendChild(scriptTagGoogletagmanager);

      return () => {
        document.head.removeChild(comment);
        document.head.removeChild(scriptGoogleURL);
        document.head.removeChild(scriptTagGoogletagmanager);
      };
    } catch (error) {}
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     try {
  //       const existingScript = document.querySelector(
  //         "script[data-posthog-script]",
  //       );

  //       if (!existingScript) {
  //         const scriptTagPosthog = document.createElement("script");
  //         scriptTagPosthog.setAttribute("data-posthog-script", "");
  //         scriptTagPosthog.innerHTML = `
  //         !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
  //         posthog.init('phc_brIJYYWZAW8qXyMm77g6vNKiK2uAUeqsPokceCbyola',{api_host:'https://app.posthog.com',persistence: 'localStorage+cookie',session_recording: {recordCrossOriginIframes: true}});
  //         posthog.identify(
  //           '${user.id}',
  //           { email: '${user.email}', name: '${user.name}'}
  //         );
  //       `;

  //         document.head.appendChild(scriptTagPosthog);

  //         console.log("Add posthog script");
  //         console.log(document.head, "document.head");
  //       }
  //     } catch (error) {
  //       console.error("Error adding PostHog script:", error);
  //     }
  //   }
  // }, []);

  const removeQueryParam = (query: string) => {
    queryParams.delete(query);

    const newSearch = queryParams.toString();

    history.push({
      search: newSearch,
    });
  };

  // if (isAddedPaymentCard) {
  //   AppToaster.show({
  //     message: `Card linked. $10 added to your balance!`,
  //     type: ToastType.SUCCESS,
  //   });
  //   removeQueryParam(QUERY_KEY_IS_ADDED_PAYMENT_CARD);
  // }

  // console.log(
  //   user?.name !== "anonymousUser" &&
  //   currentOrg._id &&
  //   userModals?.leaveWorkspaceModal.isOpen,
  //   "currentOrg",
  // );

  const SkeletonWrapper = styled.div`
    position: absolute;
    inset: 34px 0px 0px 278px;
    margin: 3px 6px;
  `;

  const StyledFullHeightSpace = styled.div`
    width: 100%;
    height: calc(100% - 30px);
    margin-top: 4px;

    .ant-skeleton {
      width: 100%;
      height: 100%;

      .ant-skeleton-image {
        width: 100%;
        height: 100%;
      }
    }
  `;

  const StyledSkeletonBtn = styled(Skeleton.Button)`
    height: 25px !important;
  `;
  return (
    <GlobalWrapper className={"CUSTOM_MAIN_CONTAINER"}>
      {user && user?.name !== "anonymousUser" && currentOrg._id && (
        <ModalWrapper
          handleMigrateWorkspace={handleMigrateWorkspace}
          handleTemplateManager={handleTemplateManager}
        />
      )}
      <Modal className="template-service-modal" open={templatedemo}>
        <div>
          <Title
            className="template-service-modal-title1"
            style={{ fontWeight: 900 }}
          >
            Boost your business intelligence with UBOS
          </Title>
          <Title
            level={4}
            className="template-service-modal-title1"
            style={{ marginBottom: "60px" }}
          >
            Explore different products for various use-cases
          </Title>

          <Row gutter={[16, 16]} justify="center">
            <Col style={{ textAlign: "start" }}>
              <Badge.Ribbon text="Generative AI">
                <Card
                  className="template-service-modal-card"
                  onClick={() => {
                    window.location.href = `https://chatbot.ubos.tech/Login?access_token=${user?.keycloakAccessToken}`;
                  }}
                  hoverable
                  style={{ width: 350 }}
                  cover={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "24px",
                        marginTop: "6px",
                      }}
                    >
                      <img
                        alt="example"
                        style={{
                          height: "80px",
                          width: "80px",
                          padding: "3px",
                        }}
                        src="https://ubos.tech/wp-content/uploads/2024/07/iconAIBot.png"
                      />
                      <h4 style={{ fontSize: "20px", marginLeft: "6px" }}>
                        Chatbot
                      </h4>
                    </div>
                  }
                >
                  <Meta
                    title="AI Chatbot for business"
                    description="Open Chatbot ➟"
                  />
                </Card>
              </Badge.Ribbon>
            </Col>
            <Col style={{ textAlign: "start" }}>
              <Card
                className="template-service-modal-card"
                onClick={() => {
                  setTemplatedemo(false);
                }}
                hoverable
                style={{ width: 350 }}
                cover={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "24px",
                      marginTop: "6px",
                    }}
                  >
                    <img
                      alt="example"
                      style={{ height: "80px", width: "80px" }}
                      src="https://ubos.tech/wp-content/uploads/2024/07/UBOS-Logo-Small.png"
                    />
                    <h4 style={{ fontSize: "20px", marginLeft: "6px" }}>
                      Platform
                    </h4>
                  </div>
                }
              >
                <Meta
                  title="Build production ready AI Apps"
                  description="Open Development Platform ➟"
                />
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
      {user && user?.name !== "anonymousUser" && !templatedemo && (
        <ToursWrapper
          handleOpenDemoTemplate={handleOpenDemoTemplate}
          handleLeftMenu={handleLeftMenu}
          openLeftMenu={openLeftMenu}
          templatedemo={templateID}
        />
      )}

      {/*<div className="global_icon">*/}
      {/*  <IconUbos></IconUbos>*/}
      {/*  <WrapperIconMenu>*/}
      {/*    <EditorIcon onClick={handleLeftMenu}></EditorIcon>*/}
      {/*  </WrapperIconMenu>*/}
      {/*</div>*/}
      <SideNavigation
        openAnalitic={handleAnalitic}
        openLeftMenu={handleLeftMenu}
        isOpenLeftMenu={openLeftMenu}
        openUserPanel={handleUserPanel}
        openTemplateManager={handleTemplateManager}
        openServiceSetting={handleServiceSetting}
        openWorkspaceSetting={handleWorkspaceSetting}
        openAdmiPanel={handleAdminPanel}
        openUserSettings={handleUserSettings}
        active={"EDIT"}
        permission={userPermissions}
        type_permission={userTypePermissions}
      ></SideNavigation>
      {openLeftMenu && (
        <div className="wrapper_left_sidebar">
          <ResizableBox
            height={window.innerHeight - 48}
            // resizeHandles={['e','n']}
            axis={"x"}
            width={widthLeftMenu}
            onResize={onResize}
            onResizeStop={() => {
              savedWidth(
                currentApplicationID,
                leftSidebarRef.current.offsetWidth,
              );
            }}
          >
            <div className="global_sidebar" ref={leftSidebarRef}>
              <div className="global_sidebar_item sidebar-tour-step">
                <GlobalSidebar
                  permission={userPermissions}
                  type_permission={userTypePermissions}
                ></GlobalSidebar>
              </div>
            </div>
          </ResizableBox>
        </div>
      )}
      <Containers>
        {isLoadingLayoutList ? (
          <SkeletonWrapper>
            <Space>
              {[1, 2, 3].map((_, index) => (
                <StyledSkeletonBtn
                  key={index}
                  active
                  size="default"
                  shape="default"
                  block={false}
                />
              ))}
            </Space>
            <br />
            <StyledFullHeightSpace>
              <Skeleton.Node active>
                <LoadingOutlined style={{ fontSize: 40, color: "#bfbfbf" }} />
              </Skeleton.Node>
            </StyledFullHeightSpace>
          </SkeletonWrapper>
        ) : (
          <>
            {contentOld && (
              <DockLayout
                ref={layoutRef}
                // layout={contentOld == "default" ? defaultLayout : contentOld}
                defaultLayout={
                  contentOld == "default" ? defaultLayout : contentOld
                }
                groups={groups}
                onLayoutChange={e => {
                  if (e.dockbox.children[0].tabs.length == 0) {
                    const newItem = {
                      id: `{\"id\":\"Get Started\",\"type\":\"nodeRed\",\"props\":\"https://us.${location.host}/StartPage\"}`,
                      component: "nodeRed",
                      title: "Get Started",
                      props: `https://us.${location.host}/StartPage`,
                    };
                    dispatch({
                      type: ReduxActionTypes.SET_LAYOUT_ITEM,
                      payload: newItem,
                    });
                  } else {
                    setTimeout(() => {
                      savedSettings(layoutRef, currentApplicationID);
                    }, 200);
                  }
                }}
                style={{
                  position: "absolute",
                  left: paddingLayout,
                  top: 34,
                  right: 0,
                  bottom: 0,
                }}
              />
            )}
          </>
        )}
      </Containers>
      {/* {isLoadingLayoutList ? (
        <h1>LOADER</h1>
      ) : (
        <Containers>
          {contentOld && (
            <DockLayout
              ref={layoutRef}
              // layout={contentOld == "default" ? defaultLayout : contentOld}
              defaultLayout={
                contentOld == "default" ? defaultLayout : contentOld
              }
              groups={groups}
              onLayoutChange={e => {
                setTimeout(() => {
                  savedSettings(layoutRef, currentApplicationID);
                }, 200);
              }}
              style={{
                position: "absolute",
                left: paddingLayout,
                top: 34,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        </Containers>
      )} */}
    </GlobalWrapper>
  );
};

MainContainer.displayName = "MainContainer";
export default MainContainer;
